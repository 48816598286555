<h2 *ngIf="hasTitle" mat-dialog-title>
  {{ title | translate }}
</h2>
<mat-dialog-content>
  <p>{{ message | translate }}</p>
  <div class="button-container flex-row flex-center">
    <div>
      <button color="accent" mat-fab>
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
