<mat-toolbar
  color="primary"
  fxFlex
  fxLayout
  fxLayoutAlign="end center"
>
  <ul fxLayout class="nav-items">
    <li *ngIf="containsLinks">
      <a
        class="nav-item"
        [routerLink]="['/home/registrations']"
        routerLinkActive="active-link"
        attr.data-text="{{
          'title.my-registrations' | translate
        }}"
        >{{ 'title.my-registrations' | translate }}</a
      >
    </li>
    <li *ngIf="containsLinks">
      <a
        class="nav-item"
        [routerLink]="['/home/bookings']"
        routerLinkActive="active-link"
        attr.data-text="{{ 'title.bookings' | translate }}"
        >{{ 'title.bookings' | translate }}</a
      >
    </li>
    <ng-container *ngIf="containsLinks && isAdmin">
      <li>
        <a
          class="nav-item"
          [routerLink]="['/home/user']"
          routerLinkActive="active-link"
          attr.data-text="{{
            'label.user-list' | translate
          }}"
          >{{ 'label.user-list' | translate }}</a
        >
      </li>
      <li>
        <a
          class="nav-item"
          [routerLink]="['/home/exam']"
          routerLinkActive="active-link"
          attr.data-text="{{
            'label.exam-list' | translate
          }}"
          >{{ 'label.exam-list' | translate }}</a
        >
      </li>
      <li>
        <a
          class="nav-item"
          [routerLink]="['/home/exam-location']"
          routerLinkActive="active-link"
          attr.data-text="{{
            'label.exam-location.list' | translate
          }}"
          >{{ 'label.exam-location.list' | translate }}</a
        >
      </li>
      <li>
        <a
          class="nav-item"
          [routerLink]="['/home/exam-center']"
          routerLinkActive="active-link"
          attr.data-text="{{
            'label.exam-center-list' | translate
          }}"
          >{{ 'label.exam-center-list' | translate }}</a
        >
      </li>
    </ng-container>
  </ul>
</mat-toolbar>
