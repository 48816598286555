import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  BookingService,
  NotificationService,
} from 'src/app/services';
import {
  LevelOfEducation,
  ParentalRole,
  PriceCalculationData,
  RadioOption,
  ReceivesBafoeg,
  SelectOption,
  Supervision,
} from 'src/app/types';

@Component({
  selector: 'app-step5-pricing-sdv',
  templateUrl: './step5-pricing-sdv.component.html',
  styleUrls: ['./step5-pricing-sdv.component.scss'],
})
export class Step5PricingSdvComponent implements OnInit {
  @Output() pricingFormValid: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() nextStep: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  form!: FormGroup;
  parentalRoleOptions: SelectOption[] = [
    {
      value: ParentalRole.MOTHER,
      label:
        'booking.sdv.pricing-page.parental-data.role.mother',
    },
    {
      value: ParentalRole.FATHER,
      label:
        'booking.sdv.pricing-page.parental-data.role.father',
    },
  ];

  parentalEducationLevelOptions: SelectOption[] = [
    {
      value: LevelOfEducation.NOT_PROVIDED,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.not-provided',
    },
    {
      value: LevelOfEducation.COLLEGE,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.college-degree',
    },
    {
      value: LevelOfEducation.MEISTER,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.german-meister',
    },
    {
      value: LevelOfEducation.APPRENTICESHIP,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.apprenticeship',
    },
    {
      value: LevelOfEducation.VOCATIONAL_SCHOOL,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.vocational-school',
    },
    {
      value: LevelOfEducation.ENGINEER,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.engineer',
    },
    {
      value: LevelOfEducation.NO_PROFESSIONAL_TRAINING,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.no-professional-training',
    },
    {
      value: LevelOfEducation.PARENT_UNKNOWN,
      label:
        'booking.sdv.pricing-page.parental-data.level-of-education.parent-unknown',
    },
  ];

  radioBtnReceivesBafoegOptions: RadioOption[] = [
    {
      value: ReceivesBafoeg.YES,
      label:
        'booking.sdv.pricing-page.receives-bafoeg.options.yes',
    },
    {
      value: ReceivesBafoeg.NO,
      label:
        'booking.sdv.pricing-page.receives-bafoeg.options.no',
    },
    {
      value: ReceivesBafoeg.UNKNOWN,
      label:
        'booking.sdv.pricing-page.receives-bafoeg.options.not-decided',
    },
  ];
  testEventSupervision!: Supervision;

  constructor(
    private bookingService: BookingService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      parentOne: [ParentalRole.MOTHER],
      parentOneLevelOfEducation: [
        LevelOfEducation.NOT_PROVIDED,
        Validators.required,
      ],

      parentTwo: [ParentalRole.FATHER],
      parentTwoLevelOfEducation: [
        LevelOfEducation.NOT_PROVIDED,
        Validators.required,
      ],
      receivesBafoeg: [
        ReceivesBafoeg.UNKNOWN,
        Validators.required,
      ],
      bafoegNumber: [null],
      informationIsCorrect: [
        { value: null, disabled: true },
      ],
    });

    // Set timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
    // TODO: Find another way to update the form in the parent component
    setTimeout(() => {
      this.pricingFormValid.emit(this.form.valid);
    }, 0);

    this.form.valueChanges.subscribe((_value) => {
      this.pricingFormValid.emit(this.form.valid);
    });
  }

  get parentOneControl(): FormControl<ParentalRole | null> {
    return this.form.get(
      'parentOne'
    ) as FormControl<ParentalRole | null>;
  }

  get parentOneLevelOfEducationControl(): FormControl<LevelOfEducation | null> {
    return this.form.get(
      'parentOneLevelOfEducation'
    ) as FormControl<LevelOfEducation | null>;
  }

  get parentTwoControl(): FormControl<ParentalRole | null> {
    return this.form.get(
      'parentTwo'
    ) as FormControl<ParentalRole | null>;
  }

  get parentTwoLevelOfEducationControl(): FormControl<LevelOfEducation | null> {
    return this.form.get(
      'parentTwoLevelOfEducation'
    ) as FormControl<LevelOfEducation | null>;
  }

  get receivesBafoegControl(): FormControl<ReceivesBafoeg | null> {
    return this.form.get(
      'receivesBafoeg'
    ) as FormControl<ReceivesBafoeg | null>;
  }

  get bafoegNumberControl(): FormControl<string | null> {
    return this.form.get('bafoegNumber') as FormControl<
      string | null
    >;
  }

  get informationIsCorrectControl(): FormControl<
    boolean | null
  > {
    return this.form.get(
      'informationIsCorrect'
    ) as FormControl<boolean | null>;
  }

  get isReceivesBafoeg(): boolean {
    return (
      this.receivesBafoegControl.value ===
      ReceivesBafoeg.YES
    );
  }

  checkIfInformationIsCorrectRequired(): void {
    if (
      this.parentOneLevelOfEducationControl.value !==
        LevelOfEducation.NOT_PROVIDED ||
      this.parentTwoLevelOfEducationControl.value !==
        LevelOfEducation.NOT_PROVIDED ||
      this.receivesBafoegControl.value !==
        ReceivesBafoeg.UNKNOWN
    ) {
      this.informationIsCorrectControl.enable();
      this.informationIsCorrectControl.setValidators([
        Validators.requiredTrue,
      ]);
      this.informationIsCorrectControl.updateValueAndValidity();
    } else {
      this.informationIsCorrectControl.reset();
      this.informationIsCorrectControl.disable();
    }
  }

  onBafoegChange(): void {
    this.checkIfInformationIsCorrectRequired();

    if (this.isReceivesBafoeg) {
      this.bafoegNumberControl.setValidators([
        Validators.required,
        Validators.pattern(/^[d.-\\]+$/),
      ]);
    } else {
      this.bafoegNumberControl.clearValidators();
      this.bafoegNumberControl.setErrors(null);
    }

    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  async cancelBooking(): Promise<void> {
    await this.bookingService
      .deleteBooking(this.bookingId)
      .finally(() => {
        this.bookingService.formState = {
          loading: false,
          item: BookingService.factoryItem(),
        };
      });

    this.router.navigate(['/home']);
  }

  updateBooking(): void {
    const updatedPriceCalculationData: PriceCalculationData =
      {
        dataProcessingForProctoringAccepted:
          this.bookingService.formState.item
            .priceCalculationData
            ?.dataProcessingForProctoringAccepted ?? false,
        parentage: [
          {
            type:
              this.parentOneControl.value ??
              ParentalRole.UNDEFINED,
            levelOfEducation:
              this.parentOneLevelOfEducationControl.value ??
              LevelOfEducation.UNDEFINED,
          },
          {
            type:
              this.parentTwoControl.value ??
              ParentalRole.UNDEFINED,

            levelOfEducation:
              this.parentTwoLevelOfEducationControl.value ??
              LevelOfEducation.UNDEFINED,
          },
        ],
        receivesBafoeg:
          this.receivesBafoegControl.value ??
          ReceivesBafoeg.UNKNOWN,
        bafoegNumber: this.bafoegNumberControl.value ?? '',
      };

    this.bookingService.formState.item.priceCalculationData =
      updatedPriceCalculationData;

    this.bookingService
      .updateBooking(this.bookingId)
      .catch(async (error) => {
        this.nextStep.emit(false);
        await this.fail(error);
      })
      .then(() => {
        this.nextStep.emit(true);
      });
  }

  private get bookingId(): number {
    return this.bookingService.formState.item.bookingId;
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'Step5PricingSdvComponent:updateBookingError',
      {
        error,
      }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }
}
