<div class="container-html">
  <mat-toolbar
    [color]="(isHandset$ | async) ? 'primary' : undefined"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="container-content flex-1"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="toggleSideNav()"
          *ngIf="isHandset$ | async"
          class="menu-button"
        >
          <mat-icon aria-label="Side nav toggle icon"
            >menu</mat-icon
          >
        </button>

        <ng-container [ngSwitch]="tenant">
          <h1
            *ngSwitchCase="'default'"
            class="app-title"
            routerLink="home"
            [class.app-title-mobile]="isHandset$ | async"
          >
            {{
              'title.' + tenant + '.application' | translate
            }}
          </h1>
          <div
            *ngSwitchCase="'sdv'"
            class="logo"
            routerLink="home"
          >
            <img
              class="sdv"
              src="/assets/img/logos/sdv-logo-sw.png"
              [alt]="'title.sdv.logo' | translate"
            />
          </div>
        </ng-container>
      </div>
      <div>
        <app-i18n-button
          *ngIf="tenant === 'default'"
        ></app-i18n-button>
        <app-profile-button></app-profile-button>
      </div>
    </div>
  </mat-toolbar>

  <app-header-navbar
    [isAdmin]="isAdmin"
    *ngIf="(isHandset$ | async) === false"
  ></app-header-navbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      class="sidenav"
      [attr.role]="
        (isHandset$ | async) ? 'dialog' : 'navigation'
      "
      [opened]="sidenavOpened"
      (click)="sidenav.toggle()"
    >
      <mat-nav-list class="nav-list">
        <a
          mat-list-item
          [routerLink]="['/home/registrations']"
          >{{ 'title.my-registrations' | translate }}</a
        >
        <a
          mat-list-item
          [routerLink]="['/home/bookings']"
          >{{ 'title.bookings' | translate }}</a
        >
        <ng-container *ngIf="isAdmin">
          <a mat-list-item [routerLink]="['/home/user']">{{
            'label.user-list' | translate
          }}</a>
          <a mat-list-item [routerLink]="['/home/exam']">{{
            'label.exam-list' | translate
          }}</a>
          <a
            mat-list-item
            [routerLink]="['/home/exam-location']"
            >{{ 'label.exam-location.list' | translate }}</a
          >
          <a
            mat-list-item
            [routerLink]="['/home/exam-center']"
            >{{ 'label.exam-center-list' | translate }}</a
          >
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="nav-content">
      <div>
        <div class="page-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer></app-footer>
</div>
