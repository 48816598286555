import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  TitleStrategy,
} from '@angular/router';
import {
  CandidateFormGuard,
  InitExamCenterFormGuard,
  InitExamFormGuard,
  InitExamLocationFormGuard,
  InitUserFormGuard,
  LoggedInGuard,
  LoggedOutGuard,
  MbrPrivacyTermsGuard,
  ResetExamCenterFormGuard,
  ResetExamFormGuard,
  ResetExamLocationFormGuard,
  ResetUserFormGuard,
  TenantDefaultGuard,
} from '../guards';
import {
  DefaultLayoutComponent,
  MbrRegistrationPageComponent,
  PlainLayoutComponent,
} from '../layouts';
import {
  CandidateFormPageComponent,
  ConsentProctoringPageComponent,
  ContactPageComponent,
  DashboardPageComponent,
  Error404PageComponent,
  ExamBookingFormPageComponent,
  ExamBookingSummaryPageComponent,
  ExamCenterFormPageComponent,
  ExamCenterPageComponent,
  ExamFormPageComponent,
  ExamLocationFormPageComponent,
  ExamLocationPageComponent,
  ExamPageComponent,
  ImprintPageComponent,
  LandingPageComponent,
  MbrRetrieveNamesPageComponent,
  MyBookingsPageComponent,
  MyRegistrationsPageComponent,
  PrivacyTermsPageComponent,
  ProfileEditPageComponent,
  ProfilePageComponent,
  RegisterPageComponent,
  ResetPasswordFormPageComponent,
  SystemRequirementsPageComponent,
  TermsOfServicePageComponent,
  UserFormPageComponent,
  UserPageComponent,
} from '../pages';
import { TemplatePageTitleStrategy } from './template-page-title.strategy';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: PlainLayoutComponent,
    children: [
      { path: 'landing', component: LandingPageComponent },
      {
        path: 'register/:token',
        component: RegisterPageComponent,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordFormPageComponent,
      },
    ],
    canActivate: [LoggedOutGuard],
  },
  {
    path: 'mbr-registration',
    component: MbrRegistrationPageComponent,
    children: [
      {
        path: 'privacy-terms',
        component: PrivacyTermsPageComponent,
        data: {
          breadcrumb: {
            skip: true,
          },
          showMbrSpecificHighlighting: true,
          showAcceptAndRejectButtons: true,
        },
        canActivate: [TenantDefaultGuard],
      },
      {
        path: 'retrieve-personal-data',
        component: MbrRetrieveNamesPageComponent,
        data: {
          breadcrumb: {
            skip: true,
          },
        },
        canActivate: [TenantDefaultGuard],
      },
      {
        path: '**',
        component: Error404PageComponent,
        pathMatch: 'full',
      },
    ],
    canActivate: [LoggedInGuard],
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            component: DashboardPageComponent,
            data: {
              breadcrumb: {
                label: 'home',
                info: 'home',
              },
            },
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'registrations',
            children: [
              {
                path: '',
                component: MyRegistrationsPageComponent,
                data: {
                  breadcrumb: 'title.test-registration',
                },
              },
              {
                path: 'exam/:id',
                component: ExamBookingFormPageComponent,
                data: {
                  breadcrumb: {
                    alias: 'examName',
                  },
                },
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'bookings',
            children: [
              {
                path: '',
                component: MyBookingsPageComponent,
                data: {
                  breadcrumb: 'title.my-bookings',
                },
              },
              {
                path: 'summary/:id',
                component: ExamBookingSummaryPageComponent,
                data: {
                  breadcrumb: {
                    alias: 'bookingName',
                  },
                },
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                component: UserPageComponent,
                data: {
                  breadcrumb: 'label.user-list',
                },
              },
              {
                path: 'create',
                component: UserFormPageComponent,
                canActivate: [ResetUserFormGuard],
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    component: UserFormPageComponent,
                    canActivate: [InitUserFormGuard],
                    data: {
                      breadcrumb: {
                        alias: 'userName',
                      },
                    },
                  },
                  {
                    path: 'booking/:id',
                    component:
                      ExamBookingSummaryPageComponent,
                    data: {
                      breadcrumb: {
                        alias: 'bookingName',
                      },
                    },
                  },
                ],
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'exam',
            children: [
              {
                path: '',
                component: ExamPageComponent,
                data: {
                  breadcrumb: 'label.exam-list',
                },
              },
              {
                path: 'create',
                component: ExamFormPageComponent,
                canActivate: [ResetExamFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examName',
                  },
                },
              },
              {
                path: ':id',
                component: ExamFormPageComponent,
                canActivate: [InitExamFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examName',
                  },
                },
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'exam-location',
            children: [
              {
                path: '',
                component: ExamLocationPageComponent,
                data: {
                  breadcrumb: 'label.exam-location.list',
                },
              },
              {
                path: 'create',
                component: ExamLocationFormPageComponent,
                canActivate: [ResetExamLocationFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examLocationName',
                  },
                },
              },
              {
                path: ':id',
                component: ExamLocationFormPageComponent,
                canActivate: [InitExamLocationFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examLocationName',
                  },
                },
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'exam-center',
            children: [
              {
                path: '',
                component: ExamCenterPageComponent,
                data: {
                  breadcrumb: 'label.exam-center-list',
                },
              },
              {
                path: 'create',
                component: ExamCenterFormPageComponent,
                canActivate: [ResetExamCenterFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examCenterName',
                  },
                },
              },
              {
                path: ':id',
                component: ExamCenterFormPageComponent,
                canActivate: [InitExamCenterFormGuard],
                data: {
                  breadcrumb: {
                    alias: 'examCenterName',
                  },
                },
              },
            ],
            canActivate: [MbrPrivacyTermsGuard],
          },
          {
            path: 'imprint',
            component: ImprintPageComponent,
            data: {
              breadcrumb: 'label.imprint',
            },
          },
          {
            path: 'terms-of-service',
            component: TermsOfServicePageComponent,
            data: {
              breadcrumb: 'label.terms-of-service',
            },
            canActivate: [TenantDefaultGuard],
          },
          {
            path: 'privacy-terms',
            component: PrivacyTermsPageComponent,
            data: {
              breadcrumb: 'label.privacy-terms',
            },
          },
          {
            path: 'system-requirements',
            component: SystemRequirementsPageComponent,
            data: {
              breadcrumb: 'label.system-requirements',
            },
          },
          {
            path: 'contact',
            component: ContactPageComponent,
            data: {
              breadcrumb: 'label.contact',
            },
          },
          {
            path: 'consent-proctoring',
            component: ConsentProctoringPageComponent,
            data: {
              breadcrumb:
                'consent-form-proctoring.sdv.title-breadcrumb',
            },
          },
        ],
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            component: ProfilePageComponent,
          },
          {
            path: 'edit',
            component: ProfileEditPageComponent,
          },
        ],
        canActivate: [MbrPrivacyTermsGuard],
      },
      {
        path: 'candidate',
        component: CandidateFormPageComponent,
        canActivate: [
          CandidateFormGuard,
          MbrPrivacyTermsGuard,
        ],
      },
      {
        path: '**',
        component: Error404PageComponent,
        pathMatch: 'full',
      },
    ],
    canActivate: [LoggedInGuard],
  },
  {
    path: '**/**',
    component: Error404PageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
