import {
  HttpClient,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  Exam,
  FormState,
  ListFilter,
  ListResponse,
  ListState,
  SelectOption,
  TestResultsUploadInfo,
  TestTemplate,
} from '../../types';

export type RegistrationsSearchFilters = {
  testId: number;
};

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  // list of exams for selections
  examSelectOptions: SelectOption[] = [];
  testTemplates: TestTemplate[] = [];
  templateSelectOptions: SelectOption[] = [];
  listState: ListState<Exam, ListFilter> = {
    loading: false,
    response: {
      items: [],
      total: 0,
    },
    filter: {
      search: '',
      sort: 'testname',
      direction: 'asc',
    },
  };
  formState: FormState<Exam> = {
    loading: false,
    item: ExamService.factoryItem(),
  };

  private readonly templateNameToLabelMappings: {
    [key: string]: string;
  } = {
    /* eslint-disable @typescript-eslint/naming-convention */
    PhaST: 'label.testtemplate.phast',
    'ITB-BUSINESS': 'label.testtemplate.itb-business',
    'TM-WISO': 'label.testtemplate.tm-wiso',
    GSAT: 'label.testtemplate.gsat',
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  constructor(private http: HttpClient) {}

  static factoryItem(): Exam {
    return {
      id: 0,
      testname: '',
      templateName: '',
      logoPath: '',
      registrationPeriodStart: '',
      registrationPeriodEnd: undefined,
      validityPeriodStart: '',
      validityPeriodEnd: undefined,
    };
  }

  loadExamListForSelect(): Promise<Exam[]> {
    const promiseList$: Promise<ListResponse<Exam>> =
      firstValueFrom(
        this.http.get<ListResponse<Exam>>(`/api/test`)
      );

    const promise$ = promiseList$.then(
      (response) => response.items
    );

    promise$.then(
      (items) =>
        (this.examSelectOptions = items.map((item) => ({
          value: item.id,
          label: item.testname,
          imagePath: item.logoPath,
        })))
    );

    return promise$;
  }

  loadExamList(): Promise<ListResponse<Exam>> {
    this.listState.loading = true;

    let params = new HttpParams()
      .set('filter', `${this.listState.filter.search}`)
      .set('sort', `${this.listState.filter.sort}`)
      .set(
        'direction',
        `${this.listState.filter.direction}`
      );

    if (
      (this.listState.filter.page || 0) > 0 &&
      (this.listState.filter.size || 0) > 0
    ) {
      params = params
        .set('page', `${this.listState.filter.page}`)
        .set('size', `${this.listState.filter.size}`);
    }

    const url = `/api/test`;
    const promise$: Promise<ListResponse<Exam>> =
      firstValueFrom(
        this.http.get<ListResponse<Exam>>(url, { params })
      );

    promise$.then(
      (response) => (this.listState.response = response)
    );

    promise$.finally(
      () => (this.listState.loading = false)
    );

    return promise$;
  }

  loadExamById(id: number): Promise<Exam> {
    return firstValueFrom(
      this.http.get<Exam>(`/api/test/${id}`)
    );
  }

  createExam(): Promise<Exam> {
    return firstValueFrom(
      this.http.post<Exam>('/api/test', this.formState.item)
    );
  }

  updateExam(): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(
        `/api/test/${this.formState.item.id}`,
        this.formState.item
      )
    );
  }

  deleteExam(id: number): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`/api/test/${id}`)
    );
  }

  saveExam(): Promise<Exam> {
    this.formState.loading = true;
    const promise =
      this.formState.item.id !== undefined &&
      this.formState.item.id > 0
        ? this.updateExam()
        : this.createExam();
    promise.finally(() => (this.formState.loading = false));
    return promise;
  }

  loadTemplateOptions(): Promise<SelectOption[]> {
    const promiseList$: Promise<
      ListResponse<TestTemplate>
    > = firstValueFrom(
      this.http.get<ListResponse<TestTemplate>>(
        `/api/testtemplates`
      )
    );

    const promise$ = promiseList$.then(
      (response) => response.items
    );

    return promise$.then((items) => {
      this.testTemplates = items;

      this.templateSelectOptions = items.map((item) => ({
        value: item.templateName,
        imagePath: item.logoPath,
        label:
          this.templateNameToLabelMappings[
            item.templateName
          ] || item.templateName,
      }));

      return this.examSelectOptions;
    });
  }

  downloadRegistrationsList(
    searchFilters: RegistrationsSearchFilters
  ): Promise<HttpResponse<Blob>> {
    const url = `/api/test/${searchFilters.testId}/registrations`;

    return firstValueFrom(
      this.http.get(url, {
        headers: {
          Accept: 'text/csv',
        },
        observe: 'response',
        responseType: 'blob',
      })
    );
  }

  async uploadResultCsv(
    testId: number,
    file: File,
    relativePath: string
  ): Promise<TestResultsUploadInfo> {
    // Upload the file to the server
    const formData = new FormData();
    const filePath = relativePath.split('/');
    formData.append(
      'file',
      file,
      filePath[filePath.length - 1]
    );

    const url = `/api/test/${testId}/testresults`;
    return firstValueFrom(
      this.http.put<TestResultsUploadInfo>(url, formData)
    );
  }
}
