import {
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AlertDialogData } from 'src/app/types';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuccessDialogComponent {
  constructor(
    public dialog: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {}

  get hasTitle(): boolean {
    return String(this.data.title || '').length > 0;
  }

  get title(): string {
    return this.data.title as string;
  }

  get message(): string {
    return this.data.message as string;
  }
}
