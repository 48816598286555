import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogData,
  ErrorDialogComponent,
  ErrorHttpDialogComponent,
  SuccessDialogComponent,
} from '../../lib/notification';
import { AlertDialogData } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private dialog: MatDialog) {}

  alert(message: string, title = ''): Promise<undefined> {
    const promise$: Promise<undefined> = firstValueFrom(
      this.dialog
        .open(AlertDialogComponent, {
          width: '450px',
          autoFocus: false,
          data: { message, title } as AlertDialogData,
        })
        .afterClosed()
    );

    return promise$;
  }

  confirm(data: ConfirmDialogData): Promise<boolean> {
    const promise$: Promise<boolean> = firstValueFrom(
      this.dialog
        .open<ConfirmDialogComponent, ConfirmDialogData>(
          ConfirmDialogComponent,
          {
            width: '450px',
            autoFocus: false,
            data,
          }
        )
        .afterClosed()
    );

    return promise$;
  }

  success(message: string, title = ''): Promise<undefined> {
    const promise$: Promise<undefined> = firstValueFrom(
      this.dialog
        .open(SuccessDialogComponent, {
          width: '400px',
          autoFocus: false,
          data: { message, title } as AlertDialogData,
        })
        .afterClosed()
    );

    return promise$;
  }

  error(message: string, title = ''): Promise<undefined> {
    const promise$: Promise<undefined> = firstValueFrom(
      this.dialog
        .open(ErrorDialogComponent, {
          width: '400px',
          autoFocus: false,
          data: { message, title } as AlertDialogData,
        })
        .afterClosed()
    );
    return promise$;
  }

  httpError(error: HttpErrorResponse): Promise<undefined> {
    const promise$: Promise<undefined> = firstValueFrom(
      this.dialog
        .open(ErrorHttpDialogComponent, {
          width: '500px',
          autoFocus: false,
          data: error.status,
        })
        .afterClosed()
    );
    return promise$;
  }
}
