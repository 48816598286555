import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { TenantId } from 'src/environments/environments.types';
import { CheckboxValidator } from '../../../lib/form-fields';
import {
  CountryService,
  GenderService,
  NotificationService,
  ProfileService,
  SnackBarService,
  UserService,
} from '../../../services';
import {
  ParentalAddressInfo,
  RadioOption,
  SelectOption,
  User,
} from '../../../types';
import { EditEmailDialogComponent } from '../../dialogs';

@Component({
  selector: 'app-profile-edit-form',
  templateUrl: './profile-edit-form.component.html',
  styleUrls: ['./profile-edit-form.component.scss'],
})
export class ProfileEditFormComponent implements OnInit {
  @HostBinding('class.form-component') hostClass = true;

  form!: UntypedFormGroup;
  maxDate!: Date;
  parentalAddressesOptions: RadioOption[] = [
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.yes',
      value: true,
    },
    {
      label:
        'userInfo.parental-addresses.radio-btn.options.no',
      value: false,
    },
  ];

  constructor(
    private countryService: CountryService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private genderService: GenderService,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private router: Router,
    private snackBar: SnackBarService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    // Set minimum age: 10 years
    this.maxDate = DateTime.now()
      .minus({ years: 10 })
      .toJSDate();

    this.userService.formState.item =
      this.profileService.profile.user;

    this.form = this.formBuilder.group({
      // block: base
      email: [
        this.item.email,
        [Validators.required, Validators.email],
      ],
      firstname: [
        this.item.firstname,
        [Validators.required],
      ],
      lastname: [this.item.lastname, [Validators.required]],
      birthName: [this.item.birthName],
      confirmedGDPR: [
        this.item.confirmedGDPR,
        [CheckboxValidator.checked],
      ],

      // block: personal
      gender: [this.item.gender, [Validators.required]],
      birthLocation: [this.item.birthplace],
      birthDate: [
        this.item.birthday,
        [Validators.required],
      ],
      phone: [this.item.phoneNumber],
      email2: [this.item.secondEmail, [Validators.email]],

      // block: address
      street: [this.item.street, [Validators.required]],
      streetNumber: [
        this.item.streetNumber,
        [Validators.required],
      ],
      addressAddition: [this.item.additionalAddressInfo],
      postalCode: [this.item.zip, [Validators.required]],
      city: [this.item.city, [Validators.required]],
      state: [this.item.state],
      country: [this.item.country, [Validators.required]],

      // parental Addresses
      parentalAddressesRadio: [
        !!this.item.parentalAddresses.length,
      ],
      parentalAddresses: this.formBuilder.array(
        this.item.parentalAddresses.map((address) =>
          this.createParentalAddressFormGroup(address)
        )
      ),
    });

    this.emailControl.disable();
    this.setCandidateValidations(this.isCandidate);
    this.setTenantValidations(this.tenant);

    this.parentalAddressesRadioControl.valueChanges.subscribe(
      (isParentalAddresses) => {
        if (
          isParentalAddresses &&
          this.item.parentalAddresses.length === 0
        ) {
          this.addParentalAddress();
        } else if (!isParentalAddresses) {
          this.removeParentalAddresses();
        }
      }
    );
  }

  get isCandidate(): boolean {
    return !this.userService.formState.item.admin;
  }

  get item(): User {
    return this.userService.formState.item;
  }

  get isCreateMode(): boolean {
    return this.item.id < 1;
  }

  get genderOptions(): SelectOption[] {
    return this.genderService.selectOptions;
  }

  get countryOptions(): SelectOption[] {
    return this.countryService.selectOptions;
  }

  // block: base
  get emailControl(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }
  get firstnameControl(): UntypedFormControl {
    return this.form.get('firstname') as UntypedFormControl;
  }
  get lastnameControl(): UntypedFormControl {
    return this.form.get('lastname') as UntypedFormControl;
  }
  get birthNameControl(): UntypedFormControl {
    return this.form.get('birthName') as UntypedFormControl;
  }
  get confirmedGDPRControl(): UntypedFormControl {
    return this.form.get(
      'confirmedGDPR'
    ) as UntypedFormControl;
  }

  // block: personal
  get genderControl(): UntypedFormControl {
    return this.form.get('gender') as UntypedFormControl;
  }
  get birthLocationControl(): UntypedFormControl {
    return this.form.get(
      'birthLocation'
    ) as UntypedFormControl;
  }
  get birthDateControl(): UntypedFormControl {
    return this.form.get('birthDate') as UntypedFormControl;
  }
  get phoneControl(): UntypedFormControl {
    return this.form.get('phone') as UntypedFormControl;
  }
  get email2Control(): UntypedFormControl {
    return this.form.get('email2') as UntypedFormControl;
  }

  // block: address
  get streetControl(): UntypedFormControl {
    return this.form.get('street') as UntypedFormControl;
  }
  get streetNumberControl(): UntypedFormControl {
    return this.form.get(
      'streetNumber'
    ) as UntypedFormControl;
  }
  get addressAdditionControl(): UntypedFormControl {
    return this.form.get(
      'addressAddition'
    ) as UntypedFormControl;
  }
  get postalCodeControl(): UntypedFormControl {
    return this.form.get(
      'postalCode'
    ) as UntypedFormControl;
  }
  get stateControl(): UntypedFormControl {
    return this.form.get('state') as UntypedFormControl;
  }
  get cityControl(): UntypedFormControl {
    return this.form.get('city') as UntypedFormControl;
  }
  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }
  get parentalAddressesRadioControl(): UntypedFormControl {
    return this.form.get(
      'parentalAddressesRadio'
    ) as UntypedFormControl;
  }
  get parentalAddressesFormArray(): FormArray<UntypedFormGroup> {
    return this.form.controls[
      'parentalAddresses'
    ] as FormArray<UntypedFormGroup>;
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.userService.formState.item = {
      ...this.userService.formState.item,

      // block: base
      firstname: this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      birthName: this.birthNameControl.value,
      confirmedGDPR: this.confirmedGDPRControl.value,

      // block: personal
      gender: this.genderControl.value,
      birthplace: this.birthLocationControl.value,
      birthday: this.birthdayFormated,
      phoneNumber: this.phoneControl.value,
      secondEmail: this.email2Control.value,

      // block: address
      street: this.streetControl.value,
      streetNumber: this.streetNumberControl.value,
      additionalAddressInfo:
        this.addressAdditionControl.value,
      zip: this.postalCodeControl.value,
      city: this.cityControl.value,
      state: this.stateControl.value,
      country: this.countryControl.value,
      parentalAddresses:
        this.parentalAddressesFormArray.value,
    };

    this.userService
      .save()
      .then(() => this.success())
      .catch((error: HttpErrorResponse) => {
        this.fail(error);
      });
  }

  get birthdayFormated(): string {
    if (this.birthDateControl.value instanceof Date) {
      const birthday = this.birthDateControl.value;
      return (
        birthday.getFullYear() +
        '-' +
        ('0' + (birthday.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + birthday.getDate()).slice(-2)
      );
    } else {
      return this.birthDateControl.value;
    }
  }

  //TODO: Delete Account
  // delete() {
  //   this.note
  //     .confirm('label.confirm-delete')
  //     .then((result) => {
  //       if (result) {
  //         this.userService.delete(this.item.id).then(() => {
  //           this.router.navigate(['/user']);
  //         });
  //       }
  //     });
  // }

  editEmail(): void {
    this.dialog.open(EditEmailDialogComponent, {
      width: '450px',
      autoFocus: false,
    });
  }

  resetPassword(): void {
    this.notificationService
      .confirm({ message: 'label.confirm-reset-password' })
      .then((result) => {
        if (result) {
          this.userService
            .sendPasswordResetMail(this.item)
            .then((_response) => {
              this.notificationService.success(
                'message.get-reset-password-successful.' +
                  this.tenant
              );
            })
            .catch(() =>
              this.notificationService.error(
                'error.general'
              )
            );
        }
      });
  }

  private setTenantValidations(tenant: string): void {
    switch (tenant) {
      case 'sdv':
        this.parentalAddressesRadioControl.setValidators([
          Validators.required,
        ]);
        break;
      default:
        this.parentalAddressesRadioControl.clearValidators();
    }

    this.parentalAddressesRadioControl.updateValueAndValidity();
  }

  private setCandidateValidations(
    isCandidate: boolean
  ): void {
    // set the validators
    if (isCandidate) {
      this.genderControl.setValidators([
        Validators.required,
      ]);
      this.birthDateControl.setValidators([
        Validators.required,
      ]);
      this.birthLocationControl.setValidators([
        Validators.required,
      ]);
      this.phoneControl.setValidators([
        Validators.required,
      ]);
      this.streetControl.setValidators([
        Validators.required,
      ]);
      this.streetNumberControl.setValidators([
        Validators.required,
      ]);
      this.postalCodeControl.setValidators([
        Validators.required,
      ]);
      this.cityControl.setValidators([Validators.required]);
      this.countryControl.setValidators([
        Validators.required,
      ]);
    } else {
      this.genderControl.clearValidators();
      this.birthDateControl.clearValidators();
      this.birthLocationControl.clearValidators();
      this.phoneControl.clearValidators();
      this.streetControl.clearValidators();
      this.streetNumberControl.clearValidators();
      this.postalCodeControl.clearValidators();
      this.cityControl.clearValidators();
      this.countryControl.clearValidators();
    }

    // is needed to apply ths validation changes
    this.genderControl.updateValueAndValidity();
    this.birthDateControl.updateValueAndValidity();
    this.birthLocationControl.updateValueAndValidity();
    this.phoneControl.updateValueAndValidity();
    this.streetControl.updateValueAndValidity();
    this.streetNumberControl.updateValueAndValidity();
    this.postalCodeControl.updateValueAndValidity();
    this.cityControl.updateValueAndValidity();
    this.countryControl.updateValueAndValidity();
  }

  private createParentalAddressFormGroup(
    address: ParentalAddressInfo
  ): FormGroup {
    return this.formBuilder.group({
      additionalAddressInfo: [
        address.additionalAddressInfo,
      ],
      city: [address.city],
      country: [address.country],
      state: [address.state],
      street: [address.street],
      streetNumber: [address.streetNumber],
      zip: [address.zip],
    });
  }

  private addParentalAddress(): void {
    const parentAddress = this.formBuilder.group({
      street: ['', Validators.required],
      streetNumber: ['', Validators.required],
      additionalAddressInfo: [''],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      state: [''],
      country: ['', Validators.required],
    });

    this.parentalAddressesFormArray.push(parentAddress);
  }

  private removeParentalAddresses(): void {
    this.parentalAddressesFormArray.clear();
    this.userService.formState.item.parentalAddresses = [];
  }

  private success(): void {
    this.snackBar.showSnackBar(
      'message.profile-edit.success'
    );
    this.router.navigate(['/profile']);
  }

  private fail(
    error: HttpErrorResponse
  ): Promise<undefined> {
    this.logger.error(
      'ProfileEditFormComponent:handleErrors',
      {
        error,
      }
    );

    const errorDialog =
      this.notificationService.httpError(error);
    return errorDialog.finally(() => Promise.reject(error));
  }

  get tenant(): TenantId {
    return environment.tenant;
  }
}
