import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-requirements-check-decline-message',
  templateUrl:
    './requirements-check-decline-message.component.html',
  styleUrls: [
    './requirements-check-decline-message.component.scss',
  ],
})
export class RequirementsCheckDeclineMessageComponent {
  @Input() question!: string;
}
