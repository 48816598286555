import { NgxLoggerLevel } from 'ngx-logger';
import packageInfo from '../../package.json';
import { Environment } from './environments.types';

export const environment: Environment = {
  apiUrl: '',
  clientId: 'boti',
  tenant: 'default',
  keycloakUrl:
    'https://auth.register.dev.itb-academic-tests.org/auth',
  logLevel: NgxLoggerLevel.DEBUG,
  production: false,
  realm: 'boti-dev',
  version: '1.4.1-887',
  mbr: {
    enabled: true,
  },
};
